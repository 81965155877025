<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add" }} Checklist Item</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="item-form">
          <v-text-field
            label="Name"
            v-model="fields.name"
            outlined
            background-color="white"
          ></v-text-field>

          <v-select
            label="Type"
            v-model="fields.type"
            :items="types"
            item-value="value"
            item-text="label"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="item-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      checklistItem: {},
      fields: {
        name: null,
        order: 100,
        type: "Standard",
      },
      types: ["Standard", "Section Header"],
      errors: {},
    };
  },

  methods: {
    openForm(checklistItem = null) {
      if (checklistItem !== null) {
        this.checklistItem = checklistItem;
        this.isEditing = true;

        this.fields.name = checklistItem.name;
        this.fields.order = checklistItem.order;
        this.fields.type = checklistItem.type;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        checklistId: this.$route.params.checklistId,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.itemId = this.checklistItem.id;
      }

      this.$store
        .dispatch("sbpm/checklistsStore/saveItem", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.checklistItem = {};
      this.fields = {
        name: null,
        order: 100,
        type: "Standard",
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
