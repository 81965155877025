<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-sbpm-checklists' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Checklists</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col class="pr-12">
          <h1>{{ checklist.name }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Checklist Items</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.checklistItem.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="header in tableHeaders"
                    :key="header.text"
                    :class="header.align === 'right' ? 'text-right' : ''"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <draggable v-model="items" tag="tbody">
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                  v-bind:class="{
                    header: item.type === 'Section Header',
                  }"
                >
                  <td>
                    <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
                  </td>
                  <!-- <td>{{ item.order }}</td> -->
                  <td>
                    {{ item.name }}
                  </td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          class="mr-2"
                          @click="$refs.checklistItem.openForm(item)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="red lighten-4 red--text"
                          v-on="on"
                          @click="openDelete(item)"
                        >
                          <v-icon x-small>mdi-archive</v-icon>
                        </v-btn>
                      </template>
                      <span>Archive</span>
                    </v-tooltip>
                  </td>
                </tr>
              </draggable>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ChecklistItem ref="checklistItem" />

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Item</v-card-title>
        <v-card-text
          >Are you sure you wish to delete item from list?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChecklistItem from "./components/ChecklistItem.vue";
import Draggable from "vuedraggable";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  components: { ChecklistItem, Draggable },

  data() {
    return {
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },

      tableHeaders: [
        { text: "", sortable: false },
        // { text: "Order", value: "order" },
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      breadcrumbs: [
        {
          text: "Checklists",
          disabled: false,
          to: { name: "module-sbpm-checklists" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    items: {
      get() {
        return this.checklist.items;
      },
      set(items) {
        const displayOrder = items.map((p) => {
          return p.id;
        });
        const appId = this.$route.params.id;
        const checklistId = this.$route.params.checklistId;

        this.$store.commit("showHideLoader", true);
        this.$store
          .dispatch("sbpm/checklistsStore/updateChecklistDisplayOrder", {
            appId,
            displayOrder,
            checklistId,
          })
          .then(() => this.$store.commit("showHideLoader", false))
          .catch(() => this.$store.commit("showHideLoader", false));
      },
    },

    checklist() {
      return this.$store.getters["sbpm/checklistsStore/get"];
    },
  },
  methods: {
    openDelete(item) {
      this.deleteForm.item = item;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.item = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("sbpm/checklistsStore/deleteItem", {
          appId: this.$route.params.id,
          checklistId: this.$route.params.checklistId,
          itemId: this.deleteForm.item.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },
  },
};
</script>

<style scoped>
tr.header {
  background-color: #f5f5f5;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
}
</style>
